<template>
  <b-form @submit="onSubmit">
    <b-card no-body v-if="strategic_plan" class="radius-top-0">
      <template #header>
        <div class="d-flex align-items-center">
          <b class="text-secondary">{{ titleForm }}</b>
          <div class="ml-auto">
            <b-button
              :to="cancelButtonLink"
              variant="outline-secondary"
              class="mr-2"
            >
              {{ cancelButtonLabel }}
            </b-button>

            <b-button
              v-if="$can('strategic_plan:create')"
              type="submit"
              variant="primary"
            >
              Salvar
            </b-button>
          </div>
        </div>
      </template>

      <b-tabs pills card vertical class="edit-menu-sidebar">
        <b-tab title="Dados Básicos" active>
          <DadosBasicos
            ref="dados_basicos"
            :strategic_plan_init="strategic_plan"
          />
        </b-tab>
        <b-tab title="Estrutura"
          ><Estrutura v-model="tree_plan_structure"
        /></b-tab>
        <b-tab title="Objetivos"><Objetivos /></b-tab>
        <b-tab title="Configurações"><Configuracoes /></b-tab>
      </b-tabs>
    </b-card>
    <b-card class="mt-3 d-none" header="Form Data Result">
      <pre class="m-0">{{ strategic_plan }}</pre>
    </b-card>
  </b-form>
</template>

<script>
import {
  DadosBasicos,
  Estrutura,
  Objetivos,
  Configuracoes
} from "../components/Forms";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    DadosBasicos,
    Estrutura,
    Objetivos,
    Configuracoes
  },
  data: () => ({
    validations: {
      dados_basicos: true
    }
  }),
  computed: {
    ...mapState("strategic_plans", ["strategic_plan", "tree_plan_structure"]),
    ...mapGetters("strategic_plans", ["getPayLoadPlanStructure"]),
    isEditMode() {
      return !!this.strategic_plan.id;
    },
    titleForm() {
      return this.isEditMode ? "Editar Plano" : "Novo plano";
    },
    cancelButtonLabel() {
      return this.isEditMode ? "Cancelar" : "Voltar";
    },
    cancelButtonLink() {
      return this.isEditMode
        ? {
            name: "strategic_plans_show",
            params: { id: this.strategic_plan.id }
          }
        : {
            name: "planos_estrategicos_list"
          };
    }
  },
  methods: {
    ...mapActions("strategic_plans", ["ActionSave", "ActionSaveTreeStructure"]),
    async onSubmit(event) {
      event.preventDefault();

      if (!this.isValid()) {
        this.$notifications.addNotification({
          message: "Os dados do formulário são inválidos",
          type: "danger"
        });
      } else {
        await this.ActionSave(this.strategic_plan);
        if (!this.strategic_plan.initial_bsc_structure) {
          await this.ActionSaveTreeStructure(this.getPayLoadPlanStructure);
        }
        this.$emit("saved", this.strategic_plan.id);
        this.$notifications.addNotification({
          message: "Registro deletado com sucesso",
          type: "success"
        });
      }
    },
    isValid() {
      this.validations.dados_basicos = this.$refs.dados_basicos.isValid();
      return Object.values(this.validations).every(Boolean);
    }
  }
};
</script>

<style></style>
